import React from 'react';
import AdminFeatPreview from '../AdminFeatPreview';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import Modal from '../../../../shared/ui/Modal';
import { FeatDetail } from '../FeatDetail';
import { useFeatCardDetailAduioPlayer } from '../../../featCardDetailAduioPlayer';
import FeatCardDetailAudioPlayer from '../../../../widgets/FeatCardDetailAudioPlayer';
import { IFeat } from '../../model/types';
import { ReactNode } from 'react';

interface IAdminFeatCard {
    feat: IFeat;
    editAction: ReactNode;
    deleteAction: ReactNode;
    publishAction: ReactNode;
}

export const AdminFeatCard: React.FC<IAdminFeatCard> = ({
    feat,
    editAction,
    deleteAction,
    publishAction,
}) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();
    const { isPlaying, isActive, togglePlayPause, stopAudio, closeAudioPlayer } =
        useFeatCardDetailAduioPlayer();
    return (
        <>
            <AdminFeatPreview
                title={feat.name}
                date={feat.publish_date}
                hero={feat.hero}
                img={feat.image_url}
                toggleModalStatus={toggleModalStatus}
                editAction={editAction}
                deleteAction={deleteAction}
                publishAction={publishAction}
            />
            {isModalOpen && (
                <Modal isOpen={isModalOpen} setStatus={toggleModalStatus}>
                    <FeatDetail
                        img={feat.image_url}
                        text={feat.description}
                        title={feat.name}
                        hero={feat.hero}
                        heroLink={feat.hero_link}
                        isPlaying={isPlaying}
                        playAudio={togglePlayPause}
                        stopAudio={stopAudio}
                        id={feat.id}
                        isChoosen={true}
                        audioSrc={feat.audio_url}
                    />
                </Modal>
            )}
            {isActive && isModalOpen && (
                <FeatCardDetailAudioPlayer
                    feat={feat}
                    isPlaying={isPlaying}
                    togglePlayPause={togglePlayPause}
                    closeAudioPlayer={closeAudioPlayer}
                    stopAudio={stopAudio}
                />
            )}
        </>
    );
};
