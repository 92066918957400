import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { audiopPlayerSlice } from '../../entities/featAudioPlayer/model';
import { contestsApi } from '../../entities/contest/model';
import { gallerySlice } from '../../entities/gallery/model/gallery';
import { featsSlice } from '../../entities/feat/model/feat';
import { sessionSlice } from '../../entities/session';
import { alertsSlice } from '../../entities/alerts';
import { authModalRoutingSlice } from '../../entities/authModal';

export const store = configureStore({
    reducer: {
        [featsSlice.name]: featsSlice.reducer,
        [audiopPlayerSlice.name]: audiopPlayerSlice.reducer,
        [contestsApi.reducerPath]: contestsApi.reducer,
        [gallerySlice.reducerPath]: gallerySlice.reducer,
        [sessionSlice.name]: sessionSlice.reducer,
        [alertsSlice.name]: alertsSlice.reducer,
        [authModalRoutingSlice.name]: authModalRoutingSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([contestsApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
store.dispatch;
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
