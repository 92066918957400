import { FeatDetail } from '../FeatDetail';
import Modal from '../../../../shared/ui/Modal';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import FeatPreview from '../FeatPreview';
import { IFeat } from '../../model/types';


interface IFeatCardProps {
    children?: React.ReactNode;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isPlaying: boolean;
    isChoosen: boolean;
    shareBtnOnClick: () => void;
    vkOnClick: () => void;
    feat: IFeat;
}

export const FeatCard: React.FC<IFeatCardProps> = ({
    playAudio,
    stopAudio,
    isPlaying,
    isChoosen,
    shareBtnOnClick,
    feat,
    vkOnClick,
}) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();

    return (
        <>
            <FeatPreview
                img={feat.image_url}
                title={feat.name}
                date={feat.publish_date}
                views={feat.views}
                shareIconOnClick={shareBtnOnClick}
                toggleModalStatus={toggleModalStatus}
                playAudio={playAudio}
                stopAudio={stopAudio}
                hero={feat.hero}
                id={feat.id}
                audioSrc={feat.audio_url}
                isPlaying={isPlaying}
                isChoosen={isChoosen}
                shareVkOnClick={vkOnClick}
            />

            {isModalOpen && (
                <Modal isOpen={isModalOpen} setStatus={toggleModalStatus}>
                    <FeatDetail
                        img={feat.image_url}
                        text={feat.description}
                        title={feat.name}
                        hero={feat.hero}
                        heroLink={feat.hero_link}
                        isPlaying={isPlaying}
                        playAudio={playAudio}
                        stopAudio={stopAudio}
                        id={feat.id}
                        isChoosen={isChoosen}
                        audioSrc={feat.audio_url}
                        shareIconOnClick={shareBtnOnClick}
                        shareVkOnClick={vkOnClick}
                    />
                </Modal>
            )}
        </>
    );
};
