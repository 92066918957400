import React, { useState } from 'react';
import styles from './index.module.scss';
import Button from '../../shared/ui/Button';
import { FeatMediaExample, featsRequest, IFeat } from '../../entities/feat';
import { FAQ__DATA, FEATS__DATA, CONTEST__ID, MEDIA__TYPE__ID } from './consts';
import { PluseIcon } from '../../shared/assets/PluseIcon';
import clsx from 'clsx';
import MailIcon from '../../pages/MainPage/ui/PartnersInfo/assets/MailIcon';
import TgLogo from '../../shared/assets/TgLogo';
import VkLogo from '../../shared/assets/VkLogo';
import Modal from '../../shared/ui/Modal';
import { useCreateModalState } from '../../shared/hooks/modal';
import CupImg from './assets/cup.png';
import MedalImg from './assets/medal.png';
import { useAppSelector, useAppDispatch } from '../../shared/store';
import { getAuthStatus } from '../../entities/session';
import { changeModalVisibleStatus } from '../../entities/authModal';
import AlertModal from '../../shared/ui/AlertModal';
import { OldGalleryItemContestForm } from '../../features/gallery/oldGalleryItemContestForm';

interface IFaqTab {
    title: string;
    text: string;
    id: number;
    isVisible: boolean;
}

interface IFaqTabsState {
    faqTabs: IFaqTab[];
    choosenTabs: IFaqTab[];
}

export const ContestModal = () => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();
    const { isModalOpen: isAlertlOpen, toggleModalStatus: toggleAlertStatus } =
        useCreateModalState();

    const [feats, setFeats] = useState<any[]>(FEATS__DATA);
    const [{ faqTabs, choosenTabs }, setTabsStatus] = useState<IFaqTabsState>({
        faqTabs: FAQ__DATA,
        choosenTabs: [],
    });

    const isAuth = useAppSelector(getAuthStatus);
    const dispatch = useAppDispatch();

    const viewForm = () => {
        isAuth ? toggleModalStatus() : dispatch(changeModalVisibleStatus());
    };

    const openTab = (choosenTab: IFaqTab) => {
        setTabsStatus((prevState) => {
            return {
                ...prevState,
                choosenTabs: choosenTabs.find((tab) => tab.id === choosenTab.id)
                    ? choosenTabs.filter((tab) => tab.id !== choosenTab.id)
                    : [...choosenTabs, choosenTab],
            };
        });
    };

    const vkOnClick = () => {
        window.open('https://vk.com/app5898182_-218711548#s=2910094', '_blank');
    };

    return (
        <>
            <article className={styles['contest-modal']}>
                <h2 className={styles['contest-modal__title']}>
                    Онлайн-акция «Расскажи о подвиге»
                </h2>
                <span className={styles['contest-modal__text']}>
                    Поделись своей историей о подвиге! В рамках акции каждый может рассказать о
                    своем подвиге, подвиге своего родственника/друга/любого, чей поступок должен
                    быть увековечен.
                </span>
                <span className={styles['contest-modal__text']}>
                    Пришли нам информацию о герое и его подвиге, а мы на основе этих данных сделаем
                    медиаматериал и опубликуем его в разделе «Подвиги». Наши писатели, художники и
                    актеры озвучки помогут твоему рассказу обрести яркую форму!
                </span>
                <div className={styles['contest-modal__actions']}>
                    <Button
                        type="button"
                        variant="orange"
                        text="Поделиться историей"
                        onClick={viewForm}
                    />
                    <Button
                        type="button"
                        variant="orange"
                        text="Подписаться на конкурс в ВК"
                        onClick={vkOnClick}
                    />
                </div>

                <h3 className={styles['contest-modal__media-examples-title']}>
                    Пример медиаматериалов
                </h3>
                <ul className={styles['contest-modal__media-examples-list']}>
                    {feats.map((feat) => {
                        return (
                            <li key={feat.id}>
                                <FeatMediaExample feat={feat} />
                            </li>
                        );
                    })}
                </ul>

                <div className={styles['contest-modal__faq']}>
                    <h3 className={styles['contest-modal__faq-title']}>Частые вопросы</h3>
                    <ul className={styles['contest-modal__faq-tabs']}>
                        {faqTabs.map((tab) => {
                            const isTabChoosen = !!choosenTabs.find(
                                (choosenTab) => choosenTab.id === tab.id
                            );
                            return (
                                <li key={tab.id} className={styles['contest-modal__faq-tab']}>
                                    <div className={styles['contest-modal__faq-title-container']}>
                                        <h4 className={styles['contest-modal__faq-tab-title']}>
                                            {tab.title}
                                        </h4>
                                        <PluseIcon
                                            className={clsx(
                                                styles['contest-modal__faq-tab-icon'],
                                                isTabChoosen &&
                                                    styles['contest-modal__faq-tab-icon--active']
                                            )}
                                            onClick={() => {
                                                openTab(tab);
                                            }}
                                        />
                                    </div>
                                    <span
                                        className={clsx(
                                            styles['contest-modal__faq-tab-text'],
                                            isTabChoosen &&
                                                styles['contest-modal__faq-tab-text--active']
                                        )}
                                    >
                                        {tab.text}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className={styles['contest-modal__social-media']}>
                    <h3 className={styles['contest-modal__social-media-title']}>
                        Остались вопросы? Напиши нам, на все ответим!
                    </h3>
                    <ul className={styles['contest-modal__social-media-list']}>
                        <li>
                            <a
                                href="mailto:podvig@ncpti.ru"
                                className={styles['contest-modal__social-media-list-item']}
                                target="_blank"
                            >
                                <MailIcon
                                    className={styles['contest-modal__social-media-list-item-icon']}
                                />
                                <span>podvig@ncpti.ru</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://vk.com/im?sel=-218711548"
                                className={styles['contest-modal__social-media-list-item']}
                                target="_blank"
                            >
                                <VkLogo
                                    className={styles['contest-modal__social-media-list-item-icon']}
                                />
                                <span>Чат ВКонтакте</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://t.me/podvigrf"
                                className={styles['contest-modal__social-media-list-item']}
                                target="_blank"
                            >
                                <TgLogo
                                    className={styles['contest-modal__social-media-list-item-icon']}
                                />
                                <span>Телеграм</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://vk.com/podvigrf"
                                className={styles['contest-modal__social-media-list-item']}
                                target="_blank"
                            >
                                <VkLogo
                                    className={styles['contest-modal__social-media-list-item-icon']}
                                />
                                <span>Группа ВК</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </article>
            <Modal
                isOpen={isModalOpen}
                setStatus={toggleAlertStatus}
                className={styles['contest-modal__contest-form']}
                additionalElements={
                    <>
                        <img
                            src={CupImg}
                            alt="кубок"
                            className={styles['contest-modal__cup-img']}
                        />
                        <img
                            src={MedalImg}
                            alt="медаль"
                            className={styles['contest-modal__medal-img']}
                        />
                    </>
                }
            >
                <OldGalleryItemContestForm contest={CONTEST__ID} mediaType={MEDIA__TYPE__ID} />
            </Modal>
            <AlertModal
                isOpen={isAlertlOpen}
                toggleAlertStatus={toggleAlertStatus}
                title="Подтвердить закрытие формы?"
                confirm={toggleModalStatus}
            />
        </>
    );
};
