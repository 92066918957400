import LeftArrowIcon from '../../../../shared/assets/LeftArrowIcon';
import styels from './index.module.scss';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import PlayIcon from '../../../../shared/assets/PlayIcon';
import RightArrowIcon from '../../../../shared/assets/RightArrowIcon';
import ListIcon from '../../../../shared/assets/ListIcon';
import CrossIcon from '../../../../shared/assets/CrossIcon';
import { OptionsList } from '../../../../shared/ui/OptionsList';
import { optionsListType, option } from '../../../../shared/utils/transformData';

interface Controls {
    isPlaying: boolean;
    togglePlayPause: () => void;
    switchNextAudio: () => void;
    switchPrevAudio: () => void;
    closePlayer: () => void;
    playList: optionsListType;
    playAudio: (id: number | undefined) => void;
    currentAudio: option;
}

export const Controls: React.FC<Controls> = ({
    isPlaying,
    togglePlayPause,
    switchNextAudio,
    switchPrevAudio,
    closePlayer,
    playList,
    playAudio,
    currentAudio,
}) => {
    return (
        <div className={styels['audioplayer__controls-container']}>
            <div className={styels['audioplayer__controls']}>
                <LeftArrowIcon className={styels['audioplayer__svg']} onClick={switchPrevAudio} />
                {isPlaying ? (
                    <PauseIcon className={styels['audioplayer__svg']} onClick={togglePlayPause} />
                ) : (
                    <PlayIcon className={styels['audioplayer__svg']} onClick={togglePlayPause} />
                )}
                <RightArrowIcon className={styels['audioplayer__svg']} onClick={switchNextAudio} />
            </div>
            <div className={styels['audioplayer__playlist-container']}>
                <ListIcon className={styels['audioplayer__svg']} />
                <OptionsList
                    options={playList}
                    changeValue={playAudio}
                    className={styels['audioplayer__playlist']}
                    choosenOption={currentAudio}
                />
            </div>
            <CrossIcon className={styels['audioplayer__svg']} onClick={closePlayer} />
        </div>
    );
};
