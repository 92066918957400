import { useNavigate } from 'react-router-dom';
import { APP__ROUTS } from '../consts';

export function useAppNavigation() {
    const navigate = useNavigate();
    const redirectToContest = () => {
        navigate(APP__ROUTS.contests);
    };

    const redirectToFeatsPage = () => {
        navigate(APP__ROUTS.feats);
    };

    const redirectToMainPage = () => {
        navigate(APP__ROUTS.main);
    };
    const redirectToPuzzle = () => {
        navigate(APP__ROUTS.puzzle);
    };

    return {
        redirectToContest,
        redirectToFeatsPage,
        redirectToMainPage,
        redirectToPuzzle,
    };
}
