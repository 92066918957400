import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';
import { IGalleryItem, GalleryState } from './types';
import { fetchMediaTypes, fetchGalleryItemLike, fetchGalleryItems } from './api';
import { IMediaType } from '../../mediaType';
import { GALLERY__RECORDS__PER__ONE__PAGINATION } from '../../../shared/consts';

const initialState: GalleryState = {
    mediaTypes: [],
    galleryItems: {},
    isGalleryItemsLoading: {},
    isGalleryItemsEnd: {},
    userContestsWork: [],
    isUserContestsWorkLoading: false,
};

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        changeGallerySection: (
            state,
            action: PayloadAction<{ mediaType: string; galleryItems: IGalleryItem[] }>
        ) => {
            if (state.mediaTypes.find((type) => type.media_type === action.payload.mediaType)) {
                state.galleryItems[action.payload.mediaType] = action.payload.galleryItems;
            }
        },
        resetGallery: (state) => {
            state.mediaTypes.forEach((mediaItem: IMediaType) => {
                state.galleryItems[mediaItem.media_type] = [];
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMediaTypes.fulfilled, (state, action) => {
            state.mediaTypes = action.payload;
            action.payload.forEach((mediaItem: IMediaType) => {
                state.galleryItems[mediaItem.media_type]
                    ? state.galleryItems[mediaItem.media_type]
                    : (state.galleryItems[mediaItem.media_type] = []);
            });
        });
        builder.addCase(fetchMediaTypes.rejected, (state) => {
            state.mediaTypes = [];
        });

        builder.addCase(fetchGalleryItems.fulfilled, (state, action) => {
            state.galleryItems[action.payload.media_type] = [
                ...state.galleryItems[action.payload.media_type],
                ...action.payload.result,
            ];
            state.isGalleryItemsLoading[action.payload.media_type] = true;
            if (action.payload.result.length === GALLERY__RECORDS__PER__ONE__PAGINATION) {
                state.isGalleryItemsEnd[action.payload.media_type] = true;
            }
            if (action.payload.result.length < GALLERY__RECORDS__PER__ONE__PAGINATION) {
                state.isGalleryItemsEnd[action.payload.media_type] = false;
            }
        });
        builder.addCase(fetchGalleryItems.pending, (state, action) => {
            state.isGalleryItemsLoading[action.meta.arg.media_type] = false;
            if (action.meta.arg.resetGalleryitems) {
                state.galleryItems[action.meta.arg.media_type] = [];
            }
        });
        builder.addCase(fetchGalleryItems.rejected, (state, action) => {
            state.galleryItems[action.meta.arg.media_type] = [];
            state.isGalleryItemsLoading[action.meta.arg.media_type] = false;
        });

        builder.addCase(fetchGalleryItemLike.fulfilled, (state, action) => {
            const updateFeatData = action.payload;

            function getChangedElemIndex(element: IGalleryItem) {
                return element.id === updateFeatData.id;
            }

            const choosenRubricIndex =
                state.galleryItems[updateFeatData.media_type].findIndex(getChangedElemIndex);

            state.galleryItems[updateFeatData.media_type].splice(choosenRubricIndex, 1, {
                ...updateFeatData,
            });
        });
    },
});

export const getMediaTypes = (state: RootState) => state['gallery'].mediaTypes;
export const getGalleryItems = (state: RootState) => state['gallery'].galleryItems;
export const getUserContestsWork = (state: RootState) => state['gallery'].userContestsWork;
export const getUserContestsWorksLoadingStatus = (state: RootState) =>
    state['gallery'].isUserContestsWorkLoading;
export const getGalleryItemsLoadingStatus = (state: RootState) =>
    state['gallery'].isGalleryItemsLoading;
export const getGalleryItemsExistStatus = (state: RootState) => state['gallery'].isGalleryItemsEnd;

export const { changeGallerySection, resetGallery } = gallerySlice.actions;
