import styels from './index.module.scss';
import PlayIcon from '../../../../shared/assets/PlayIcon';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import ShareButton from '../../../../shared/ui/ShareButton';

interface IFeatDetail {
    title: string;
    hero: string;
    text: string;
    img: string;
    isPlaying: boolean;
    id: number;
    heroLink: string;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isChoosen: boolean;
    audioSrc: string;
    shareIconOnClick?: () => void;
    shareVkOnClick?: () => void;
}

export const FeatDetail: React.FC<IFeatDetail> = ({
    title,
    hero,
    text,
    img,
    isPlaying,
    heroLink,
    stopAudio,
    playAudio,
    id,
    isChoosen,
    audioSrc,
    shareIconOnClick,
    shareVkOnClick,
}) => {
    const audioIcon =
        isChoosen && isPlaying ? (
            <PauseIcon className={styels['feat-story__play-icon']} />
        ) : (
            <PlayIcon className={styels['feat-story__play-icon']} />
        );
    return (
        <article className={styels['feat-story']}>
            <h1 className={styels['feat-story__title']}>{title}</h1>
            <div className={styels['feat-story__content-container']}>
                <div className={styels['feat-story__content']}>
                    <address>
                        <a href={heroLink} className={styels['feat-story__hero-link']} target='_blank'>
                            {hero}
                        </a>
                    </address>
                    {audioSrc && (
                        <div
                            className={styels['feat-story__audio-container']}
                            onClick={(event) => {
                                isChoosen && isPlaying ? stopAudio(event) : playAudio(event, id);
                            }}
                        >
                            {audioIcon}
                            <span>Слушай аудиорассказ</span>
                        </div>
                    )}
                    <p className={styels['feat-story__text']}>{text}</p>
                    <ShareButton
                        shareIconOnClick={shareIconOnClick}
                        shareVkOnClick={shareVkOnClick}
                    />
                </div>
                <div className={styels['feat-story__img-container']}>
                    <img
                        src={BASE__MEDIA__URL + img}
                        alt={title}
                        className={styels['feat-story__img']}
                    ></img>
                </div>
            </div>
        </article>
    );
};
