import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE__API__URL } from '../../../shared/consts';
import { IFeat } from './types';
import { rubricsRequest } from '../../featRubric';
import { IFeatsRubrics } from './types';

export interface IFeatsPaginationOption {
    page?: number;
    page_size: number;
    rubrics?: number[];
    sort_views?: boolean;
    check_status?: boolean;
}

export const fetchFeats = createAsyncThunk(
    'feats/fetchFeats',
    async function ({
        page = 1,
        page_size,
        rubrics = [],
        sort_views = false,
        check_status = true,
    }: IFeatsPaginationOption): Promise<IFeat[]> {
        try {
            const featsResponse = await featsRequest(
                page,
                page_size,
                rubrics,
                sort_views,
                check_status
            );

            return featsResponse;
        } catch (err) {
            return [];
        }
    }
);

export async function featsRequest(
    page = 1,
    page_size: number,
    rubrics: [] | number[] = [],
    sort_views = false,
    check_status = true
) {
    try {
        const rubricsParams = rubrics?.map((id) => `rubrics=${id}&`).join('');
        const response = await fetch(
            `${BASE__API__URL}feats?${rubricsParams ? rubricsParams : ''}page=${page}&page_size=${page_size}&sort_views=${sort_views}&check_status=${check_status}`
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export const fetchFeatById = createAsyncThunk(
    'feats/fetchFeatById',
    async function (id: number): Promise<IFeat> {
        try {
            const response = await fetch(`${BASE__API__URL}feats/${id}`);
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error();
            }
        } catch (err) {
            throw new Error();
        }
    }
);

export const fetchRubrics = createAsyncThunk('feats/fetchRubrics', async function (): Promise<
    IFeatsRubrics
    > {
    try {
        const response = await rubricsRequest();
        return response;
    } catch (err) {
        return [];
    }
});

export async function getFeatById(id: number): Promise<IFeat | null> {
    try {
        let feat = null;
        if (id) {
            const request = await fetch(`${BASE__API__URL}feats/${id}`);
            if (request.ok) {
                feat = await request?.json();
            } else {
                feat = null;
            }
        }

        return feat;
    } catch (err) {
        return null;
    }
}
