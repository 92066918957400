import React from 'react';
import styels from './index.module.scss';
import PlayIcon from '../../../../shared/assets/PlayIcon';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import ViewIcon from '../../assets/ViewIcon';
import { FeatDetail } from '../FeatDetail';
import Modal from '../../../../shared/ui/Modal';
import ShareButton from '../../../../shared/ui/ShareButton';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { getDayMothYearDateFormat } from '../../../../shared/utils/date';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import { fetchFeatById } from '../../model/api';
import { useAppDispatch } from '../../../../shared/store';

interface IFeatPreview {
    title: string;
    date: string;
    hero: string;
    img: string;
    isPlaying: boolean;
    id: number;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isChoosen: boolean;
    audioSrc: string;
    shareIconOnClick?: () => void;
    views: number;
    toggleModalStatus: () => void;
    shareVkOnClick?: () => void;
}

export const FeatPreview: React.FC<IFeatPreview> = ({
    title,
    hero,
    img,
    isPlaying,
    stopAudio,
    playAudio,
    id,
    isChoosen,
    audioSrc,
    shareIconOnClick,
    views,
    date,
    toggleModalStatus,
    shareVkOnClick,
}) => {
    const formatDate = getDayMothYearDateFormat(date);

    const dispatch = useAppDispatch();

    const openFeatDetails = () => {
        toggleModalStatus();
        dispatch(fetchFeatById(id));
    };

    const audioIcon =
        isPlaying && isChoosen ? (
            <PauseIcon
                onClick={(event) => {
                    stopAudio(event);
                }}
                className={styels['feat-card__play-icon']}
            />
        ) : (
            <PlayIcon
                onClick={(event) => {
                    playAudio(event, id);
                }}
                className={styels['feat-card__play-icon']}
            />
        );
    return (
        <div className={styels['feat-card__container']} onClick={openFeatDetails}>
            <article className={styels['feat-card']}>
                {audioSrc && audioIcon}
                <div className={styels['feat-card__img-container']}>
                    <img
                        className={styels['feat-card__img']}
                        src={`${BASE__MEDIA__URL}${img}`}
                        alt="pic"
                    />
                </div>
                <div className={styels['feat-card__preview-detail']}>
                    <h2 className={styels['feat-card__title']}>{title}</h2>
                    <span className={styels['feat-card__hero']}>{hero}</span>
                    <time className={styels['feat-card__date']}>{formatDate}</time>
                </div>

                <div className={styels['feat-card__footer']}>
                    <div className={styels['feat-card__view-stat']}>
                        <ViewIcon className={styels['feat-card__view-icon']} />
                        <span>{views}</span>
                    </div>
                    <ShareButton
                        shareIconOnClick={shareIconOnClick}
                        shareListClasses=""
                        shareVkOnClick={shareVkOnClick}
                    />
                </div>
            </article>
        </div>
    );
};

export default FeatPreview;
