import { IMediaType } from './types';
import { BASE__API__URL } from '../../../shared/consts';

export async function requestMediaTypes(): Promise<IMediaType[] | []> {
    try {
        const response = await fetch(`${BASE__API__URL}gallery/media_types`);
        if (response.ok) {
            const data: IMediaType[] = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export async function getMediaTypeById(id: string): Promise<IMediaType | null> {
    try {
        const response = await fetch(`${BASE__API__URL}gallery/media_types/${id}`);
        if (response.ok) {
            const data: IMediaType = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
}
