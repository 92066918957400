import React from 'react';
import PagePreloader from '../../shared/ui/PagePreloader';
import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';

const PuzzlePageLazy = lazyRetry(() => import('../../pages/PuzzlePage/index'));
const PuzzleStartPageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleStartPage'));
const PuzzleGamePageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleGame'));
const PuzzleTestPageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleTest/index'));
const PuzzleFinalLvl = lazyRetry(() => import('../../pages/PuzzlePage/ui/FinalLvl/index'));
const PuzzleRefQuestion = lazyRetry(() => import('../../pages/PuzzlePage/ui/RefQuestion/index'));
const FinalRefQuestion = lazyRetry(
    () => import('../../pages/PuzzlePage/ui/FinalRefQuestion/index')
);

export const PuzzleRoutes = [
    {
        path: APP__ROUTS.puzzle,
        element: (
            <Suspense fallback={<PagePreloader />}>
                <PuzzlePageLazy />
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: (
                    <Suspense>
                        <PuzzleStartPageLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.puzzleGame,
                element: (
                    <Suspense>
                        <PuzzleGamePageLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.puzzleTest,
                element: (
                    <Suspense>
                        <PuzzleTestPageLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.puzzleFinal,
                element: (
                    <Suspense>
                        <PuzzleFinalLvl />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.puzzleRefQuestion,
                element: (
                    <Suspense>
                        <PuzzleRefQuestion />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.puzzleFinalRefQuestion,
                element: (
                    <Suspense>
                        <FinalRefQuestion />
                    </Suspense>
                ),
            },
        ],
    },
];
