import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { BASE__API__URL } from '../consts';
import { STATUS__CODE } from '../consts';
import { APP__ROUTS } from '../consts';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE__API__URL,
    // credentials: 'include', отправлять с запросами куки/токены
});

export const baseQueryWithReAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === STATUS__CODE.notAuth) {
        // api.dispatch(changeAuthStatus(false));

        window.location.assign(APP__ROUTS.main);
    }
    return result;
};
