import { BASE__API__URL } from '../../../shared/consts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGalleryItem } from './types';
import { requestMediaTypes, IMediaType } from '../../mediaType';
import { STATUS__CODE, APP__ROUTS } from '../../../shared/consts';

export const fetchMediaTypes = createAsyncThunk(
    'gallery/fetchMediaTypes',
    async function (): Promise<IMediaType[] | []> {
        try {
            const response = await requestMediaTypes();
            return response;
        } catch (err) {
            return [];
        }
    }
);

interface IfetchGalleryItemsOptions {
    page_size: number;
    media_type: string;
    sort_by_likes: boolean;
    page: number;
    resetGalleryitems?: boolean;
}

interface IfetchMediaResponse {
    result: IGalleryItem[];
    media_type: string;
}

export const fetchGalleryItems = createAsyncThunk(
    'gallery/fetchGalleryItems',
    async function ({
        page,
        page_size,
        media_type,
        sort_by_likes,
    }: IfetchGalleryItemsOptions): Promise<IfetchMediaResponse> {
        try {
            const response = await requestGalleryItems(page, page_size, media_type, sort_by_likes);
            if (response.length) {
                return { result: response, media_type };
            } else {
                return { result: [], media_type };
            }
        } catch (err) {
            return { result: [], media_type };
        }
    }
);

export async function requestGalleryItems(
    page = 1,
    page_size: number,
    media_type: string,
    sort_by_likes: boolean,
    sort_by_check_status = true
): Promise<IGalleryItem[] | []> {
    try {
        const mediaType = media_type ? `&media_type=${media_type}` : '';
        const sortByLike = sort_by_likes ? `&sort_by_likes=${sort_by_likes}` : '';
        const response = await fetch(
            `${BASE__API__URL}gallery?page=${page}&page_size=${page_size}${mediaType}${sortByLike}&check_status=${sort_by_check_status}`
        );

        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export const fetchGalleryItemLike = createAsyncThunk(
    'gallery/fetchGalleryItemLike',
    async function (id: number): Promise<IGalleryItem> {
        try {
            const response = await fetch(`${BASE__API__URL}gallery/${id}/like`, { method: 'POST' });
            if (response.ok) {
                return await response?.json();
            } else if (response.status === STATUS__CODE.notAuth) {
                window.location.replace(APP__ROUTS.main);
                throw new Error();
            } else {
                throw new Error();
            }
        } catch (err) {
            throw new Error();
        }
    }
);

export async function requestUserContestsWorks(checkStatus: boolean): Promise<IGalleryItem[] | []> {
    try {
        const response = await fetch(
            `${BASE__API__URL}gallery/me_media?check_status=${checkStatus}`
        );
        if (response.ok) {
            const data: IGalleryItem[] = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export async function dwnldCertificate(galleryItemId: number) {
    try {
        const response = await fetch(
            `${BASE__API__URL}gallery_contests/download-certificate?media_id=${galleryItemId}`
        );
        if (response.ok) {
            const result = await response.blob();
            const url = window.URL.createObjectURL(result);
            return url;
        } else {
            return '';
        }
    } catch (err) {
        return '';
    }
}
