import styels from './index.module.scss';
import clsx from 'clsx';
import { optionsListType, option } from '../../utils/transformData';

interface IOptionsList {
    options: optionsListType;
    changeValue: (id: number | undefined) => void;
    className?: string;
    choosenOption?: option;
}

export const OptionsList: React.FC<IOptionsList> = ({ options, changeValue, className = '', choosenOption }) => {
    return (
        <ul className={clsx(className, styels['search__list'])}>
            {options.map((el, index: number) => {
                return (
                    <li
                        className={clsx(styels['search__list-item'], choosenOption?.value === el.value && styels['search__list-item--choosen'])}
                        key={index}
                        onClick={() => changeValue(el.value)}
                    >
                        {el.option}
                    </li>
                );
            })}
        </ul>
    );
};
