import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { FeatsState, IFeat } from './types';
import { fetchFeats, fetchRubrics, fetchFeatById } from './api';
import { IFeatsRubric } from './types';
import { RECORDS__PER__ONE__PAGINATION } from './consts';

const initialState: FeatsState = {
    isFeatsLoading: false,
    isRubricLoading: false,
    feats: [],
    rubrics: [],
    page: 1,
    choosenRubrics: [],
    isFeatsExist: true,
};

export const featsSlice = createSlice({
    name: 'feats',
    initialState,
    reducers: {
        changeFeatsLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isFeatsLoading = action.payload;
        },

        changeRubrics: (state, action: PayloadAction<IFeatsRubric>) => {
            state.feats = [];

            const choosenRubric = action.payload;

            function getChoosenRubricIndex(element: IFeatsRubric) {
                return element.id === choosenRubric.id;
            }

            const choosenRubricIndex = state.choosenRubrics.findIndex(getChoosenRubricIndex);
            choosenRubricIndex !== -1
                ? (state.choosenRubrics = state.choosenRubrics.filter(
                    (rubric) => rubric.id !== choosenRubric.id
                ))
                : state.choosenRubrics.push(choosenRubric);
        },
        changeFeatPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFeats.fulfilled, (state, action: PayloadAction<IFeat[]>) => {
            const result = [...state.feats, ...action.payload];

            const unique: IFeat[] = result.reduce<IFeat[]>((accumulator, current) => {
                if (accumulator.findIndex((object) => object.id === current.id) === -1) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
            state.feats = [...unique];
            state.isFeatsLoading = true;

            if (action.payload.length === RECORDS__PER__ONE__PAGINATION) {
                state.isFeatsExist = true;
            }
            if (action.payload.length < RECORDS__PER__ONE__PAGINATION) {
                state.isFeatsExist = false;
            }
        });
        builder.addCase(fetchFeats.pending, (state) => {
            state.isFeatsLoading = false;
        });
        builder.addCase(fetchFeats.rejected, (state) => {
            state.feats = [];
            state.isFeatsLoading = false;
        });

        builder.addCase(fetchRubrics.fulfilled, (state, action) => {
            state.rubrics = action.payload.map((rubric) => {
                return { ...rubric, isChoosen: false };
            });
            state.isRubricLoading = true;
        });
        builder.addCase(fetchRubrics.rejected, (state) => {
            state.rubrics = [];
            state.isRubricLoading = false;
        });
        builder.addCase(fetchFeatById.fulfilled, (state, action) => {
            const updateFeatData = action.payload;

            function getChangedFeatIndex(element: IFeat) {
                return element.id === updateFeatData.id;
            }

            const choosenRubricIndex = state.feats.findIndex(getChangedFeatIndex);

            state.feats.splice(choosenRubricIndex, 1, {
                ...updateFeatData,
            });
        });
        builder.addCase(fetchFeatById.rejected, (state) => {
            console.log('err');
        });
    },
});

export const getFeats = (state: RootState) => state['feats'].feats;
export const getFeatsRubruics = (state: RootState) => state['feats'].rubrics;
export const getFeatsRubricsLoadingStatus = (state: RootState) => state['feats'].isRubricLoading;
export const getFeatsLoadinStatus = (state: RootState) => state['feats'].isFeatsLoading;
export const getCurrentPage = (state: RootState) => state['feats'].page;
export const getChoosenFeatsRubrics = (state: RootState) => state['feats'].choosenRubrics;
export const getFeatsExistStatus = (state: RootState) => state['feats'].isFeatsExist;

export const { changeRubrics, changeFeatsLoadingStatus, changeFeatPage } = featsSlice.actions;
