import Modal from '../../shared/ui/Modal';
import { useCreateModalState } from '../../shared/hooks/modal';
import styles from './index.module.scss';
import Gramophone from './assets/camera.png';
import CupImg from './assets/star.png';
import MedalImg from './assets/compas.png';
import Button from '../../shared/ui/Button';
import { useEffect, useState } from 'react';
import { getDayMothYearDateFormat } from '../../shared/utils/date';
import { useNavigate } from 'react-router-dom';
import { APP__ROUTS } from '../../shared/consts';

interface IContestBanner {
    isAlwaysActive?: boolean;
}

const CONTEST__ID = 14;

const ContestBanner: React.FC<IContestBanner> = ({ isAlwaysActive = false }) => {
    const [bannerStatus, setBannerStatus] = useState(isAlwaysActive);
    const navigate = useNavigate();
    const vkOnClick = () => {
        window.open('https://vk.com/app5898182_-218711548#s=2321967', '_blank');
    };
    const redirectToContest = () => {
        navigate(APP__ROUTS.contests + '/' + APP__ROUTS.contetSubmitForm + `?contest=${CONTEST__ID}`);
        setBannerStatus(false);
    };
    useEffect(() => {
        const viewBanner = setTimeout(() => {
            const popUpData = localStorage.getItem('podvig-popup-data');
            if (popUpData === null) {
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                const popUpData = {
                    [currentDate]: true,
                };
                localStorage.setItem('podvig-popup-data', JSON.stringify(popUpData));
                setBannerStatus(true);
            }
            if (popUpData) {
                const popUpDataHashMap = JSON.parse(popUpData);
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                if (popUpDataHashMap[currentDate] === undefined) {
                    const newData = { ...popUpDataHashMap, [currentDate]: true };
                    localStorage.setItem('podvig-popup-data', JSON.stringify(newData));
                    setBannerStatus(true);
                }
            }
        }, 15000);
        return () => {
            clearTimeout(viewBanner);
        };
    }, []);
    return (
        <>
            <Modal
                isOpen={bannerStatus}
                modalClassName={styles['banner__modal']}
                setStatus={() => {
                    setBannerStatus(false);
                }}
                additionalElements={
                    <>
                        <img src={MedalImg} className={styles['banner__medal-img']}></img>
                        <img src={CupImg} className={styles['banner__cup-img']}></img>
                        <img src={Gramophone} className={styles['banner__gramophone-img']}></img>
                    </>
                }
            >
                <article className={styles['banner']}>
                    <h2 className={styles['banner__title']}>
                        ПРИМИ УЧАСТИЕ В ФОТОКВЕСТЕ «СЛЕД» ГЛАВНЫЙ ПРИЗ:{' '}
                        <span className={styles['banner__title__detail']}> 50 ТЫСЯЧ РУБЛЕЙ!</span>
                    </h2>
                    <span className={styles['banner__sub__title']}>
                        СЛЕДуй за кадром вместе с Подвиг.РФ и Яндекс-картами!
                    </span>
                    <span className={styles['banner__description']}>
                        Присылай свои фотоработы и выиграй главный приз 50 тысяч рублей, фирменный
                        мерч и дипломы конкурса.
                    </span>
                    <div className={styles['banner__actions-container']}>
                        <Button
                            text="Подробнее"
                            variant="white"
                            type="button"
                            onClick={redirectToContest}
                        />
                        <Button
                            text="Подписаться на акцию в ВК"
                            variant="light-orange-gradient"
                            type="button"
                            onClick={vkOnClick}
                        />
                    </div>
                </article>
            </Modal>
        </>
    );
};

export default ContestBanner;
